import React, { useState } from 'react';
import Logo from '../assets/images/logo.png';
import 'aos/dist/aos.css';

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <div>
      <nav data-aos="fade-down" data-aos-anchor-placement="top-center" className="relative w-full z-10">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <a href="/" className="flex items-center">
            <img src={Logo} alt="ChequeMate Logo" className='w-20' />
          </a>
          <div className="hidden md:flex flex-1 justify-center space-x-8">
            <a href="/results" className="py-2 px-3 text-[13px] text-white hover:text-yellow-400" aria-current="page">Results</a>
            <a href="/calculators" className="py-2 px-3 text-[13px] text-white hover:text-yellow-400">Calculators</a>
          </div>
          <a href='https://whop.com/hub/chequemate' target='_blank' rel="noreferrer" className="text-[13px] font-semibold tracking-[-0.01em] leading-[1.5em] text-black px-4 py-3 bg-gradient-to-bl from-yellow-300 to-yellow-500 rounded-[6px] hidden md:inline-flex hover:scale-[102%]">
            Dashboard
          </a>
          <div className="flex md:hidden items-center">
            <button
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-gray-500 rounded-lg"
              aria-controls="navbar-sticky"
              aria-expanded={isOpen}
              onClick={toggleMenu}
            >
              <span className="sr-only">Open main menu</span>
              <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="" viewBox="0 0 17 14">
                <path stroke="#facc15" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
              </svg>
            </button>
          </div>
        </div>
      </nav>
      <div className={`${isOpen ? "block" : "hidden"} md:hidden`}>
        <ul className="flex flex-col items-center py-4 space-y-2">
          <li>
            <a href="/results" className="block py-2 px-3 text-[13px] text-white hover:text-yellow-400" aria-current="page">Results</a>
          </li>
          <li>
            <a href="/calculators" className="block py-2 px-3 text-[13px] text-white hover:text-yellow-400">Calculators</a>
          </li>
          {/* <li>
            <a href="/free-course" className="block py-2 px-3 text-[13px] text-white hover:text-yellow-400 hover:bg-gray-100 md:hover:bg-transparent md:text-gray-900 md:p-0">Free Course</a>
          </li> */}
        </ul>
      </div>
    </div>
  );
}
