import React, { useState } from 'react';

const MatchBetting = () => {
  const [betType, setBetType] = useState('Qualifying Bet');
  const [backStake, setBackStake] = useState('');
  const [backOdds, setBackOdds] = useState('');
  const [layOdds, setLayOdds] = useState('');
  const [commission, setCommission] = useState('');
  const [layAmount, setLayAmount] = useState(0);
  const [betfairBalance, setBetfairBalance] = useState(0);
  const [percentageReturn, setPercentageReturn] = useState(0);

  const handleInputChange = (setter) => (e) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setter(value);
    }
  };

  const handleCalculate = () => {
    const stake = parseFloat(backStake);
    const backOddsNum = parseFloat(backOdds);
    const layOddsNum = parseFloat(layOdds);
    const bookieCommission = parseFloat(commission) / 100;

    let calculatedLayAmount = 0;
    let calculatedBetfairBalance = 0;
    let calculatedPercentageReturn = 0;

    if (betType === 'Qualifying Bet') {
        calculatedLayAmount = backOddsNum / (layOddsNum - bookieCommission) * stake;
        calculatedBetfairBalance = calculatedLayAmount * (layOddsNum - 1);
        calculatedPercentageReturn = (stake * (backOddsNum - 1) - calculatedBetfairBalance) / stake * 100;
      } else if (betType === 'Bonus SNR') {
        calculatedLayAmount = (backOddsNum - 1) / (layOddsNum - bookieCommission) * stake;
        calculatedBetfairBalance = calculatedLayAmount * (layOddsNum - 1);
        calculatedPercentageReturn = (stake * (backOddsNum - 1) - calculatedBetfairBalance) / stake * 100;
      } else if (betType === 'Bonus SR') {
        calculatedLayAmount = (backOddsNum / (layOddsNum - bookieCommission)) * stake;
        calculatedBetfairBalance = calculatedLayAmount * (layOddsNum - 1);
        calculatedPercentageReturn = 100 + ((stake * (backOddsNum - 1) - calculatedBetfairBalance) / stake * 100);
    }

    setLayAmount(calculatedLayAmount);
    setBetfairBalance(calculatedBetfairBalance);
    setPercentageReturn(calculatedPercentageReturn);
  };

  return (
    <div className="flex flex-col md:flex-row items-center justify-center text-white p-6">
      <div data-aos="zoom-out-right" data-aos-anchor-placement="top-center" className="md:w-1/2 p-4 md:p-8">
        <h1 className="text-3xl font-bold mb-4">Matched Betting Calculator</h1>
        <p className="text-lg mb-6">
          Our matched betting calculator is your essential tool for optimizing stakes and maximizing returns on Betfair.
          Designed to streamline your betting strategy, this calculator caters to various betting scenarios with four distinct modes.
        </p>
        <p className="text-lg mb-2"><strong>Qualifying/Mug Bet Mode</strong> helps you determine the exact stake needed for straightforward cash bets to meet bonus requirements or mug requirements.</p>
        <p className="text-lg mb-2"><strong>Bonus SNR Mode</strong> is tailored for bonuses where the stake is not returned (SNR), allowing you to effectively manage your bonus stake.</p>
        <p className="text-lg"><strong>Bonus SR Mode</strong> addresses bonuses where the stake is returned (SR), providing precise calculations for such offers.</p>
      </div>

      <div data-aos="zoom-out-left" className="md:w-1/2 bg-[#1A1A1A] p-6 rounded-lg shadow-md">
        <div className="mb-4">
          <label htmlFor="betType" className="block text-sm font-medium mb-2">Select Bet Type:</label>
          <select
            id="betType"
            className="w-full p-2 rounded-lg bg-white/10 border border-white/15"
            value={betType}
            onChange={(e) => setBetType(e.target.value)}
          >
            <option>Qualifying Bet</option>
            <option>Bonus SNR</option>
            <option>Bonus SR</option>
          </select>
        </div>

        <div className="mb-4">
          <label htmlFor="backStake" className="block text-sm font-medium mb-2">Back Stake ($):</label>
          <input
            type="text"
            id="backStake"
            className="w-full p-2 rounded-lg bg-white/10 border border-white/15"
            value={backStake}
            onChange={handleInputChange(setBackStake)}
          />
        </div>

        <div className="mb-4">
          <label htmlFor="backOdds" className="block text-sm font-medium mb-2">Back Odds:</label>
          <input
            type="text"
            id="backOdds"
            className="w-full p-2 rounded-lg bg-white/10 border border-white/15"
            value={backOdds}
            onChange={handleInputChange(setBackOdds)}
          />
        </div>

        <div className="mb-4">
          <label htmlFor="layOdds" className="block text-sm font-medium mb-2">Lay Odds:</label>
          <input
            type="text"
            id="layOdds"
            className="w-full p-2 rounded-lg bg-white/10 border border-white/15"
            value={layOdds}
            onChange={handleInputChange(setLayOdds)}
          />
        </div>

        <div className="mb-4">
          <label htmlFor="commission" className="block text-sm font-medium mb-2">Commission (%):</label>
          <input
            type="text"
            id="commission"
            className="w-full p-2 rounded-lg bg-white/10 border border-white/15"
            value={commission}
            onChange={handleInputChange(setCommission)}
          />
        </div>

        <button
          onClick={handleCalculate}
          className="w-full p-2 bg-gradient-to-bl from-yellow-300 to-yellow-500 rounded-lg text-black font-bold"
        >
          Calculate
        </button>

        <div className="mt-6">
          <h2 className="text-xl font-bold mb-4">Results</h2>
          <p>Lay Amount: ${layAmount.toFixed(2)}</p>
          <p>Betfair Balance: ${betfairBalance.toFixed(2)}</p>
          <p>Percentage Return: {percentageReturn.toFixed(2)}%</p>
        </div>
      </div>
    </div>
  );
};

export default MatchBetting;
