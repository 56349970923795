import React, { useState } from 'react';

const Arbitrage = () => {
  const [odds1, setOdds1] = useState('');
  const [odds2, setOdds2] = useState('');
  const [stake, setStake] = useState('');
  const [stake1, setStake1] = useState(0);
  const [stake2, setStake2] = useState(0);
  const [payout1, setPayout1] = useState(0);
  const [payout2, setPayout2] = useState(0);
  const [totalPayout, setTotalPayout] = useState(0);
  const [totalProfit, setTotalProfit] = useState(0);
  const [roi, setRoi] = useState(0);

  const handleInputChange = (setter) => (e) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setter(value);
    }
  };

  const handleCalculate = () => {
    const odds1Num = parseFloat(odds1);
    const odds2Num = parseFloat(odds2);
    const stakeNum = parseFloat(stake);

    if (!odds1Num || !odds2Num || !stakeNum) return;

    const arbPercentage = (1/odds1Num + 1/odds2Num);
    const stakeForOdds1 = (stakeNum * (1 / odds1Num)) / arbPercentage;
    const stakeForOdds2 = (stakeNum * (1 / odds2Num)) / arbPercentage;

    const payout1Value = stakeForOdds1 * odds1Num;
    const payout2Value = stakeForOdds2 * odds2Num;
    const totalPayoutValue = payout1Value + payout2Value;
    const totalStake = stakeForOdds1 + stakeForOdds2;
    const totalProfitValue = (totalStake / arbPercentage) - totalStake;
    const roiValue = (totalProfitValue / totalStake) * 100;

    setStake1(stakeForOdds1.toFixed(2));
    setStake2(stakeForOdds2.toFixed(2));
    setPayout1(payout1Value.toFixed(2));
    setPayout2(payout2Value.toFixed(2));
    setTotalPayout(totalPayoutValue.toFixed(2));
    setTotalProfit(totalProfitValue.toFixed(2));
    setRoi(roiValue.toFixed(2));
  };

  return (
    <div className="flex flex-col md:flex-row items-center justify-center text-white p-6">
      <div data-aos="zoom-out-right" className="md:w-1/2 p-4 md:p-8">
        <h1 className="text-3xl font-bold mb-4">Arbitrage Calculator</h1>
        <p className="text-lg mb-6">
          The arbitrage calculator should be used to determine the optimal stake size for your bets. To use the calculator, first input the two odds of the bet into the <strong>"Bet 1"</strong> and <strong>"Bet 2"</strong> input boxes. Then, enter the stake size you wish to use to bet. Once you click <strong>"Calculate"</strong>, your optimal stake size will show up, as well as your total profit, and the arbitrage ROI.
        </p>
        <p className="text-lg mb-2">Arbitrage betting, often referred to as <strong>"arbing",</strong> is a strategy that allows you to take advantage of differing odds offered by bookmakers to guarantee a profit, regardless of the outcome of the event. By placing bets on all possible outcomes with different bookmakers, you can <strong>lock in a profit no matter who wins</strong>.</p>
        <p className="text-lg mb-2">In our Discord, we post daily arbitrage bets, giving you the opportunity to capitalize on these discrepancies. Our team monitors the markets closely, ensuring that you receive the best opportunities to make consistent profits through this risk-free betting method.</p>
      </div>

      <div data-aos="zoom-out-left" className="md:w-1/2 bg-[#1A1A1A] p-6 rounded-lg shadow-md">
        <div className="mb-4">
          <label htmlFor="odds1" className="block text-sm font-medium mb-2">Bet 1 Odds:</label>
          <input
            type="text"
            id="odds1"
            className="w-full p-2 rounded-lg bg-white/10 border border-white/15"
            value={odds1}
            onChange={handleInputChange(setOdds1)}
          />
        </div>

        <div className="mb-4">
          <label htmlFor="odds2" className="block text-sm font-medium mb-2">Bet 2 Odds:</label>
          <input
            type="text"
            id="odds2"
            className="w-full p-2 rounded-lg bg-white/10 border border-white/15"
            value={odds2}
            onChange={handleInputChange(setOdds2)}
          />
        </div>

        <div className="mb-4">
          <label htmlFor="stake" className="block text-sm font-medium mb-2">Stake ($):</label>
          <input
            type="text"
            id="stake"
            className="w-full p-2 rounded-lg bg-white/10 border border-white/15"
            value={stake}
            onChange={handleInputChange(setStake)}
          />
        </div>

        <button
          onClick={handleCalculate}
          className="w-full p-2 bg-gradient-to-bl from-yellow-300 to-yellow-500 rounded-lg text-black font-bold mb-4"
        >
          Calculate
        </button>

        <div className="mt-6">
          <h2 className="text-xl font-bold mb-4">Results</h2>
          <p>Stake (for Bet 1): ${stake1}</p>
          <p>Stake (for Bet 2): ${stake2}</p>
          <p>Payout (for Bet 1): ${payout1}</p>
          <p>Payout (for Bet 2): ${payout2}</p>
          <p>Total Payout: ${totalPayout}</p>
          <p>Total Profit: ${totalProfit}</p>
          <p>ROI: {roi}%</p>
        </div>
      </div>
    </div>
  );
};

export default Arbitrage;
