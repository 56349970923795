import React, { Suspense, lazy } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init({
  startEvent: 'DOMContentLoaded',
  initClassName: 'aos-init',
  animatedClassName: 'aos-animate',
  useClassNames: false,
  disableMutationObserver: false,
  debounceDelay: 50,
  throttleDelay: 99,
  offset: 120,
  delay: 0,
  duration: 400,
  easing: 'ease',
  once: false,
  mirror: false,
});

const Navbar = lazy(() => import('./components/navBar'));
const Hero = lazy(() => import('./components/hero'));
const FeaturesSection = lazy(() => import('./components/features'));
const ProfitChart = lazy(() => import('./components/success'));
const LookInside = lazy(() => import('./components/lookInside'));
const PricingPlans = lazy(() => import('./components/pricingPlans'));
const TestimonialCarousel = lazy(() => import('./components/testimonial'));
const Faq = lazy(() => import('./components/faq'));
const Support = lazy(() => import('./components/support'));
const Socials = lazy(() => import('./components/socials'));
const Footer = lazy(() => import('./components/footer'));

export default function App() {
  return (
    <div className="font-be-vietnam min-h-screen">
      <Suspense fallback={<div>Loading Navbar...</div>}>
        <Navbar />
      </Suspense>

      <Suspense fallback={<div>Loading Hero...</div>}>
        <Hero />
      </Suspense>

      <Suspense fallback={<div>Loading Content...</div>}>
        <FeaturesSection />
        <ProfitChart />
        <LookInside />
        <PricingPlans />
        <TestimonialCarousel />
        <Faq />
        <Support />
        <Socials />
      </Suspense>

      <Suspense fallback={<div>Loading Footer...</div>}>
        <Footer />
      </Suspense>
    </div>
  );
}
