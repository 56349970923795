import React, { useState } from 'react';
import MatchBetting from '../components/calculatorPages/matchBetting';
import Arbitrage from '../components/calculatorPages/arbitrage';

import NavBar from '../components/navBar';

const CalculatorsPage = () => {
  const [activeCalculator, setActiveCalculator] = useState('matchedBetting');

  return (
    <div className="flex flex-col min-h-screen text-white">
      <NavBar />
      <div data-aos="fade-down" data-aos-anchor-placement="top-center" className="mb-6 flex justify-center">
        <button
          onClick={() => setActiveCalculator('matchedBetting')}
          className={`p-2 mx-2 rounded ${activeCalculator === 'matchedBetting' ? 'bg-yellow-500' : 'bg-[#1A1A1A]'}`}
        >
          Matched Betting Calculator
        </button>
        <button
          onClick={() => setActiveCalculator('arbitrage')}
          className={`p-2 mx-2 rounded ${activeCalculator === 'arbitrage' ? 'bg-yellow-500' : 'bg-[#1A1A1A]'}`}
        >
          Arbitrage Calculator
        </button>
      </div>

      {activeCalculator === 'matchedBetting' && <MatchBetting />}
      {activeCalculator === 'arbitrage' && <Arbitrage />}
    </div>
  );
};

export default CalculatorsPage;
