import React, { useEffect, useState, useRef } from 'react';
import { Line, Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

import NavBar from '../components/navBar';

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const subHeadings = [
  { label: "Bet Slips", key: "betSlips" },
  { label: "Horse Racing", key: "horseRacing" }
];

const unitSizes = [10, 25, 50, 100];

const ResultsPage = () => {
  const [activeTab, setActiveTab] = useState('betSlips');
  const [timePeriod, setTimePeriod] = useState('daily');
  const [selectedUnitSize, setSelectedUnitSize] = useState(10);
  const [sheetData, setSheetData] = useState(null);
  
  const [chartData, setChartData] = useState({});
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  const [barData, setBarData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          'https://docs.google.com/spreadsheets/d/1bfsa7toyFiVyrVKc07LtIxKgNhqzw6H9JxnyqtAjvIM/gviz/tq?tqx=out:json&gid=2071010609'
        );
        const text = await response.text();
        const json = JSON.parse(text.substr(47).slice(0, -2));
        const rows = json.table.rows;
  
        if (!rows) {
          console.error('No rows found in the data.');
          return;
        }
  
        const labels = rows
          .filter(row => row.c && row.c[0] && row.c[0].v) 
          .map(row => row.c[0].v);
  
        const data = rows
          .filter(row => row.c && row.c[3] && row.c[3].v)
          .map(row => row.c[3].v);
  
        setChartData({
          labels,
          datasets: [
            {
              label: 'Cumulative Profit',
              data,
              borderColor: '#DDB12D',
              borderWidth: 1, 
              backgroundColor: 'rgba(221, 177, 45, 0.1)', 
            },
          ],
        });
      } catch (error) {
        console.error('Error fetching or parsing data:', error);
      }

      try {
        const response = await fetch(
          'https://docs.google.com/spreadsheets/d/1bfsa7toyFiVyrVKc07LtIxKgNhqzw6H9JxnyqtAjvIM/gviz/tq?tqx=out:json&gid=2071010609'
        );
        const text = await response.text();
        const json = JSON.parse(text.substr(47).slice(0, -2));
        const rows = json.table.rows;

        if (!rows) {
          console.error('No rows found in the data.');
          return;
        }

        const data = {
          daily: {
            betSlips: {
              totalDays: rows[3]?.c[27]?.v || 'N/A',
              daysWon: rows[3]?.c[28]?.v || 'N/A',
              winRate: (rows[3]?.c[29]?.v.toFixed(4) || 0) * 100 + '%',
              totalProfit: rows[2]?.c[36]?.v.toFixed(2) + ' U' || 'N/A'
            },
            horseRacing: {
              totalDays: rows[8]?.c[27]?.v || 'N/A',
              daysWon: rows[8]?.c[28]?.v || 'N/A',
              winRate: (rows[8]?.c[29]?.v.toFixed(4) || 0) * 100 + '%',
              totalProfit: rows[7]?.c[36]?.v.toFixed(2) + ' U' || 'N/A'
            }
          },
          weekly: {
            betSlips: {
              totalDays: rows[3]?.c[30]?.v || 'N/A',
              daysWon: rows[3]?.c[31]?.v || 'N/A',
              winRate: (rows[3]?.c[32]?.v.toFixed(3) || 0) * 100 + '%',
              totalProfit: rows[2]?.c[36]?.v.toFixed(2) + ' U' || 'N/A'
            },
            horseRacing: {
              totalDays: rows[8]?.c[30]?.v || 'N/A',
              daysWon: rows[8]?.c[31]?.v || 'N/A',
              winRate: (rows[8]?.c[32]?.v.toFixed(4) || 0) * 100 + '%',
              totalProfit: rows[7]?.c[36]?.v.toFixed(2) + ' U' || 'N/A'
            }
          },
          monthly: {
            betSlips: {
              totalDays: rows[3]?.c[33]?.v || 'N/A',
              daysWon: rows[3]?.c[34]?.v || 'N/A',
              winRate: (rows[3]?.c[35]?.v.toFixed(4) || 0) * 100 + '%',
              totalProfit: rows[2]?.c[36]?.v.toFixed(2) + ' U' || 'N/A'
            },
            horseRacing: {
              totalDays: rows[8]?.c[33]?.v || 'N/A',
              daysWon: rows[8]?.c[34]?.v || 'N/A',
              winRate: (rows[8]?.c[35]?.v.toFixed(4) || 0) * 100 + '%',
              totalProfit: rows[7]?.c[36]?.v.toFixed(2) + ' U' || 'N/A'
            }
          },
          avgMonthly: {
            betSlips: {
              tenUnit: (rows[0]?.c[25]?.v.toFixed(2) || 0),
              twentyfiveUnit: (rows[1]?.c[25]?.v.toFixed(2) || 0),
              fiftyUnit: (rows[2]?.c[25]?.v.toFixed(2) || 0),
              hundredUnit: (rows[3]?.c[25]?.v.toFixed(2) || 0),
            },
            horseRacing: {
              tenUnit: (rows[7]?.c[25]?.v.toFixed(2) || 0),
              twentyfiveUnit: (rows[8]?.c[25]?.v.toFixed(2) || 0),
              fiftyUnit: (rows[9]?.c[25]?.v.toFixed(2) || 0),
              hundredUnit: (rows[10]?.c[25]?.v.toFixed(2) || 0),
            }
          }
        };

        setSheetData(data);
      } catch (error) {
        console.error('Error fetching or parsing data:', error);
      }

      try {
        const response = await fetch(
          'https://docs.google.com/spreadsheets/d/1bfsa7toyFiVyrVKc07LtIxKgNhqzw6H9JxnyqtAjvIM/gviz/tq?tqx=out:json&gid=2071010609'
        );
        const text = await response.text();
        const json = JSON.parse(text.substr(47).slice(0, -2));
        const rows = json.table.rows;
  
        if (!rows) {
          console.error('No rows found in the data.');
          return;
        }
  
        const columnIndices = {
          betSlips: {
            daily: { label: 5, data: 6 },
            weekly: { label: 8, data: 9 },
            monthly: { label: 11, data: 12 },
          },
          horseRacing: {
            daily: { label: 14, data: 15 },
            weekly: { label: 17, data: 18 },
            monthly: { label: 20, data: 21 },
          },
        };
  
        const { label, data } = columnIndices[activeTab][timePeriod];
  
        const labels = rows
          .slice(1) 
          .filter(row => row.c && row.c[label] && row.c[label].v) 
          .map(row => row.c[label].v);

        const chartData = rows
          .slice(1) 
          .filter(row => row.c && row.c[data] && row.c[data].v) 
          .map(row => row.c[data].v);
  
        setBarData({
          labels,
          datasets: [
            {
              label: 'Profit',
              data: chartData,
              backgroundColor: 'rgba(221, 177, 45, 0.6)',
              borderColor: '#DDB12D',
              borderWidth: 1,
            },
          ],
        });
      } catch (error) {
        console.error('Error fetching or parsing data:', error);
      }
    };

    fetchData();
  
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
        chartInstanceRef.current = null;
      }
    };
  }, [activeTab, timePeriod]);

  const customPlugin = {
    id: 'customLinePlugin',
    afterDraw: (chart) => {
      const { ctx, chartArea: { left, right, top, bottom } } = chart;
      ctx.save();

      const lineOffset = 5;

      ctx.beginPath();
      ctx.moveTo(left - lineOffset, bottom + lineOffset);
      ctx.lineTo(right, bottom + lineOffset);
      ctx.lineWidth = 1;
      ctx.strokeStyle = '#fff';
      ctx.stroke();

      ctx.beginPath();
      ctx.moveTo(left - lineOffset, top);
      ctx.lineTo(left - lineOffset, bottom + lineOffset);
      ctx.lineWidth = 1;
      ctx.strokeStyle = '#fff';
      ctx.stroke();

      ctx.restore();
    }
  };

  const options = {
      scales: {
        x: {
          display: false,
        },
        y: {
          beginAtZero: true,
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: (context) => {
              const date = context.label;
              const value = context.raw.toFixed(2);
              return `Date: ${date}, Profit: ${value}`;
            },
          },
        },
      },
  };

  const barOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.dataset.label || '';
            const value = context.raw.toFixed(2);
            return `${label}: ${value}`;
          },
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  const handleTabChange = (tabKey) => {
    setActiveTab(tabKey);
  };

  const handleTimePeriodChange = (period) => {
    setTimePeriod(period);
  };

  const handleUnitSizeChange = (size) => {
    setSelectedUnitSize(size);
  };

  if (!sheetData) {
    return <div>Loading...</div>;
  }

  const renderData = sheetData[timePeriod][activeTab];
  const avgMonthlyData = sheetData.avgMonthly[activeTab];
  const unitSizeKey = {
    10: 'tenUnit',
    25: 'twentyfiveUnit',
    50: 'fiftyUnit',
    100: 'hundredUnit'
  }[selectedUnitSize];

  const averageMonthlyProfit = avgMonthlyData[unitSizeKey] || 'N/A';

  return (
    <div className="">
      <NavBar />
      <div className='text-white p-8 sm:p-20 pt-28'>
        <div className="relative flex max-w-[58rem] flex-col space-y-4 mb-8">
          <h2 data-aos="fade-up" className="font-bold text-3xl leading-[1.1] text-[42px] text-white"><span className='text-yellow-400'>OFFICIAL</span> CHEQUEMATE RESULTS</h2>
          <p data-aos="fade-up" className="max-w-[85%] leading-normal text-muted-foreground text-lg sm:leading-7 text-[#B9BABF]">
            We believe in complete transparency. All of our results are published in REAL TIME for anyone to see. Check it out for yourself!
          </p>
        </div>

        <div data-aos="fade-up" className="flex mb-4">
          {subHeadings.map(subHeading => (
            <button
              key={subHeading.key}
              className={`px-4 py-2 mr-2 ${activeTab === subHeading.key ? 'bg-yellow-400/90' : 'bg-[#1A1A1A]'} rounded-lg`}
              onClick={() => handleTabChange(subHeading.key)}
            >
              {subHeading.label}
            </button>
          ))}
        </div>

        <div data-aos="fade-up" className="flex mb-4">
          <button
            className={`px-4 py-2 mr-2 ${timePeriod === 'daily' ? 'bg-yellow-400/90' : 'bg-[#1A1A1A]'} rounded-lg`}
            onClick={() => handleTimePeriodChange('daily')}
          >
            Daily
          </button>
          <button
            className={`px-4 py-2 mr-2 ${timePeriod === 'weekly' ? 'bg-yellow-400/90' : 'bg-[#1A1A1A]'} rounded-lg`}
            onClick={() => handleTimePeriodChange('weekly')}
          >
            Weekly
          </button>
          <button
            className={`px-4 py-2 ${timePeriod === 'monthly' ? 'bg-yellow-400/90' : 'bg-[#1A1A1A]'} rounded-lg`}
            onClick={() => handleTimePeriodChange('monthly')}
          >
            Monthly
          </button>
        </div>

        <div data-aos="fade-up" className="grid grid-cols-2 gap-4 mb-8">
          <div className="bg-[#1A1A1A] p-4 rounded-lg">
            <h2 className="text-xl font-bold">{timePeriod === 'daily' ? 'Total Days' : timePeriod === 'weekly' ? 'Total Weeks' : 'Total Months'}</h2>
            <p className="text-3xl">{renderData.totalDays || renderData.totalWeeks || renderData.totalMonths}</p>
          </div>
          <div className="bg-[#1A1A1A] p-4 rounded-lg">
            <h2 className="text-xl font-bold">{timePeriod === 'daily' ? 'Days Won' : timePeriod === 'weekly' ? 'Weeks Won' : 'Months Won'}</h2>
            <p className="text-3xl">{renderData.daysWon || renderData.weeksWon || renderData.monthsWon}</p>
          </div>
          <div className="bg-[#1A1A1A] p-4 rounded-lg">
            <h2 className="text-xl font-bold">Win Rate</h2>
            <p className="text-3xl">{renderData.winRate}</p>
          </div>
          <div className="bg-[#1A1A1A] p-4 rounded-lg">
            <h2 className="text-xl font-bold">Total Profit</h2>
            <p className="text-3xl">{renderData.totalProfit}</p>
          </div>
        </div>


        <div data-aos="fade-up" className="bg-[#1A1A1A] p-6 rounded-lg mb-8">
          <h2 className="text-2xl font-bold mb-4">Average Monthly Profit</h2>
          <p className="text-4xl font-bold text-yellow-400">${averageMonthlyProfit}</p>
          <div className="flex my-4">
            {unitSizes.map(size => (
              <button
                key={size}
                className={`px-4 py-2 mr-2 ${selectedUnitSize === size ? 'bg-yellow-400/90' : 'bg-white/10'} rounded-lg`}
                onClick={() => handleUnitSizeChange(size)}
              >
                ${size}
              </button>
            ))}
          </div>
          {barData.labels ? (
            <Bar data={barData} options={barOptions} />
          ) : (
            <p>Loading...</p>
          )}
        </div>

        <div data-aos="fade-up" className="bg-[#1A1A1A] p-6 rounded-lg">
          <h2 className="text-2xl font-bold mb-4">How much have we made our members?</h2>
          {chartData && chartData.labels ? (
            <Line ref={chartRef} data={chartData} options={options} plugins={[customPlugin]} />
          ) : (
            <p>Loading...</p>
          )}
          <a href="https://docs.google.com/spreadsheets/d/1bfsa7toyFiVyrVKc07LtIxKgNhqzw6H9JxnyqtAjvIM/edit?gid=960568170#gid=960568170" target='_blank' className="block mt-4 text-center text-yellow-400" rel="noreferrer">Click for more results</a>      
        </div>
      </div>
    </div>
  );
};

export default ResultsPage;
